<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运行监管</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">错误日志</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start; ">
          <div class="searchbox" style="margin-bottom:5px">
            <div title="操作时间:" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem;">用户系统时间:</span>
              <el-date-picker
                clearable
                size="small"
                v-model="seachData.loginTime"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="yyyy-MM-dd HH:mm:ss"
              ></el-date-picker>
            </div>
            <div class="btnBox">
              <el-button style="margin-left:20px" class="bgc-bv" round @click="getData()">查询</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column label="编号" align="center" type="index" fixed :index="indexMethod" width="100px"/>
              <el-table-column
                label="系统名"
                align="center"
                prop="deviceSystemName"
                show-overflow-tooltip
                width="60"
                fixed
              />
              <el-table-column
                label="系统版本号"
                align="center"
                prop="deviceSystemVersion"
                show-overflow-tooltip
                width="80"
              />
              <el-table-column
                label="手机厂商"
                align="center"
                show-overflow-tooltip
                prop="deviceType"
                width="110"
              ></el-table-column>

              <el-table-column
                label="手机型号"
                align="center"
                prop="deviceModel"
                show-overflow-tooltip
                width="80"
              />
              <el-table-column
                label="APP版本号"
                align="center"
                prop="appBuildVersion"
                show-overflow-tooltip
                width="90"
              />
              <el-table-column
                label="网络"
                align="center"
                prop="netType"
                show-overflow-tooltip
                width="60"
              />
              <el-table-column
                label="请求路径"
                align="center"
                prop="requestUrl"
                show-overflow-tooltip
                minWidth="200"
              ></el-table-column>
              <el-table-column
                label="请求参数"
                align="center"
                prop="parameters"
                show-overflow-tooltip
                minWidth="200"
              ></el-table-column>
              <el-table-column
                label="错误信息"
                align="center"
                prop="reason"
                show-overflow-tooltip
                minWidth="300"
              >
                <template slot-scope="scope">
                  <span>{{scope.row.reason}}</span>
                </template>
              </el-table-column>

              <el-table-column
                label="用户系统时间"
                align="center"
                show-overflow-tooltip
                prop="date"
                minWidth="140"
                fixed="right"
              >
                <template slot-scope="scope">{{scope.row.date | moment}}</template>
              </el-table-column>

              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "OperationSupervision/errorAPP",
  components: {
    Empty,
    PageNum
  },
  mixins: [List],
  data() {
    return {
      seachData: {
        loginTime: []
      }
    };
  },
  computed: {},
  created() {},
  methods: {
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        dataType: "json"
      };

      if (this.seachData.loginTime && this.seachData.loginTime.length > 0) {
        params.startDate = this.seachData.loginTime[0];
        params.endDate = this.seachData.loginTime[1];
      }
      this.doFetch({
        url: "/logCenter/queryAppErrLog",
        params,
        pageNum
      });
    },

    //
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 32;
      }
      this.tableHeight = tHeight;
    }
  }
};
</script>
